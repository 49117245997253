/**
 * Few helper functions that can be utilized throughout the code.
 *
 * Note: Keep this file small and clean.
 */

import { LanderCookie, QueryParam } from './Constants';
import { Logger } from './Logger';

/**
 * Return domain name in the order:
 *  - query parameter
 *  - hostname from location
 * @returns string
 */
export function getDomainName(queryConfig) {
  // check domain in query param
  if (queryConfig.hasOwnProperty(QueryParam.DOMAIN)) {
    return queryConfig[QueryParam.DOMAIN];
  }

  // return hostname from the url
  return window.location.hostname;
}

/**
 * Returns HTTP query string from Object. Ignores null values.
 * Eg. key=val&key2=val2
 * @param obj
 */
export function objectToQueryStringExcludeNull(obj) {
  // https://eslint.org/docs/rules/guard-for-in
  const kv = [];
  for (const key in obj) {
    if (obj.hasOwnProperty(key) && obj[key] !== null) {
      kv.push(key + '=' + encodeURIComponent(obj[key]));
    }
  }
  return kv.length > 0 ? kv.join('&') : '';
}

/**
 * Deep copy object using iteration
 * @param src
 */
export function copyObject(src) {
  const target = {};
  for (const prop in src) {
    if (src.hasOwnProperty(prop)) {
      target[prop] = src[prop];
    }
  }
  return target;
}

/**
 * Creates a string representation of object, array or string
 * @param val
 * @returns string
 */
export function toString(val) {
  if (typeof val === 'object') {
    try {
      return JSON.stringify(val);
    } catch (e) {
      Logger.error(e);
      return val;
    }
  }
  if (Array.isArray(val)) {
    return val.toString();
  }
  return val;
}

/**
 * Convert to boolean
 * @param val
 * @returns Boolean
 */
export function parseBoolean(val) {
  if (val === true || val === 'true' || val === 1 || val === '1') {
    return true;
  } else if (val === false || val === 'false' || val === 0 || val === '0') {
    return false;
  }
  return null;
}

/**
 * Returns true if the object is empty or undefined
 * @param obj
 * @returns {boolean}
 */
export function isEmptyOrUndefined(obj) {
  if (obj === null || typeof obj === 'undefined') {
    return true;
  }
  return Object.keys(obj).length === 0;
}

/**
 * If the variable is an array, the function will returned the values joined by the separator argument
 * (if the separator is not provided, then the values are joined without any separator).
 * @param variable
 * @param separator
 * @returns {*}
 */
export function joinIfArray(variable, separator = ',') {
  if (variable === null || typeof variable === 'undefined') {
    return;
  }
  if (Array.isArray(variable)) {
    return variable.join(separator);
  }
  return variable;

}

/**
 * If the argument is an array, returns its length, or else returns 1.
 * @param arg
 * @returns {int}
 */
export function getLength(arg) {
  if (Array.isArray(arg)) {
    return arg.length;
  }
  return 1;
}

/**
 * Check if the user agent is a mobile device
 * @returns {boolean} True if mobile, False otherwise
 */
export function isMobileBrowser() {
  if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
    return true;
  }
  return false;
}

/**
 * Replaces placeholders ${key} contained in the text with the value from the valueMap entries.
 * For example:
 * let text = "The domain ${domain} may be for sale.";
 * let valueMap = {}; valueMap["domain"] = "awesome.com";
 * let replaced = substitutePlaceholders(text, valueMap);
 * @param templateString
 * @param valueMap
 * @returns string
 */
export function substitutePlaceholders(templateString, valueMap) {
  for (const key in valueMap) {
    if (valueMap.hasOwnProperty(key)) {
      const placeholder = '${' + key + '}'; // This must be a regexp, otherwise only replaces the first occurrence
      const regexp = new RegExp(escapeRegExp(placeholder), 'g');
      if (typeof templateString !== 'undefined') {
        templateString = templateString.replace(regexp, valueMap[key]);
      }
    }
  }
  return templateString;
}

/**
 * Escapes input that is to be treated as a literal string within a regular expression
 * that would otherwise be mistaken for a special character.
 * See https://developer.mozilla.org/en-US/docs/Web/JavaScript/Guide/Regular_Expressions
 * @param string
 * @returns string
 */
function escapeRegExp(string) {
  return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string
}

/**
 * The parameters of the function above are the name of the cookie (cname), the value of
 * the cookie (cvalue), and the number of hours until the cookie should expire (exhours).
 * The function sets a cookie by adding together the cookiename, the cookie value, and
 * the expires string.
 * @param cname
 * @param cvalue
 * @param exhours
 */
export function setCookie(cname, cvalue, ttl) {
  const d = new Date();
  d.setTime(d.getTime() + ttl);
  const expires = 'expires=' + d.toUTCString();
  document.cookie = cname + '=' + cvalue + ';' + expires + ';path=/';
}

/**
 * The parameters of the function above are the name of the cookie (cname), the value of
 * the cookie (cvalue), and the number of hours until the cookie should expire (exhours).
 * The function sets a cookie by adding together the cookiename, the cookie value, and
 * the expires string.
 * @param cname
 * @returns {string}
 */
export function getCookie(cname) {
  const name = cname + '=';
  const ca = document.cookie.split(';');
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) === ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }
  return '';
}

/**
 * Return the protocol of the current URL in UPPER CASE
 * @returns {string}
 */
export function getUrlProtocol() {
  return window.location.protocol.replace(/:$/, '').toUpperCase();
}

/**
 * Returns UUID (this might not be universally unique but serves our purpose)
 * @returns {string}
 */
export function generateUUID() {
  let d = new Date().getTime();
  if (typeof performance !== 'undefined' && typeof performance.now === 'function') {
    d += performance.now(); // use high-precision timer if available
  }

  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    var r = (d + Math.random() * 16) % 16 | 0;
    d = Math.floor(d / 16);
    // eslint-disable-next-line no-mixed-operators
    return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16);
  });
}

/**
 * This function checks if the parking visitor cookie is set by calling the getCookie function.
 * If the cookie is set it will return its current value.
 * If the cookie is not set, it will create a new visitor identifier, and store the cookie
 * for x number of hours, by calling the setCookie function.
 * @returns {string}
 */
export function getVisitorIdentifier() {
  let visitorId = getCookie(LanderCookie.VISITOR_ID);
  if (!visitorId) {
    visitorId = generateUUID();
  }

  const cookieTtl = 365 * 86400000;  // 1 year (365 * 24 * 60 * 60 * 1000)

  // set/reset cookie time
  setCookie(LanderCookie.VISITOR_ID, visitorId, cookieTtl);
  return visitorId;
}

/**
 * Time zone objects containing the offset from UTC and a city for info
 * (example: UTC time is 10:00, MST time is 03:00 => offset is -7)
 */
export const TimeZone = {
  MST: {
    offset: -7,
    city: 'Phoenix'
  }
};

/**
 *
 * @param timeZone use the TimeZone in HelperFunctions
 * @param format such as 'yyyy:MM:dd HH:mm:ss'
 * @returns {*}
 */
export function getTime(timeZone, format) {
  return getFormattedTime(getDate(timeZone.offset), format);
}

export function getFormattedTime(date, format) {

  const symbol = {
    yyyy: date.getFullYear(),
    MM: date.getMonth() < 10 ? '0' + (date.getMonth() + 1) : '' + (date.getMonth() + 1),
    dd: date.getDate() < 10 ? '0' + date.getDate() : '' + date.getDate(),
    HH: date.getHours() < 10 ? '0' + date.getHours() : '' + date.getHours(),
    mm: date.getMinutes() < 10 ? '0' + date.getMinutes() : '' + date.getMinutes(),
    ss: date.getSeconds() < 10 ? '0' + date.getSeconds() : '' + date.getSeconds()
  };

  const arr = [...format];
  const symbols = [];
  let prevChar = null;
  let currChar;
  let currSym = '';
  for (let i = 0; i < arr.length; i++) {
    currChar = arr[i];
    prevChar === null ? prevChar = '' : prevChar = arr[i - 1];
    if (currChar !== prevChar) {
      currSym += prevChar;
      if (currSym !== '') {
        symbols.push(currSym);
      }
      currSym = '';
    } else {
      currSym += currChar;
    }
  }
  symbols.push(prevChar + currSym);

  const elements = symbols.map(s => {
    if (s in symbol) {
      return symbol[s];
    }
    return s;
  });

  return elements.join('');

}

/**
 * Returns a new date calculated with the offset from the UTC time (see TimeZone)
 * @param offset
 * @returns {Date}
 */
export function getDate(offset) {

  // current date and time, specified in the local time zone
  const d = new Date();

  // convert date to milliseconds, add local time zone offset and get UTC time in milliseconds
  const utc = d.getTime() + (d.getTimezoneOffset() * 60000);

  // create new Date object for different city using supplied offset
  return new Date(utc + (3600000 * offset));

}
