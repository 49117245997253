import { Logger } from './Logger';
import { LanderCookie, FeedProvider, EventType, System } from '../commons/Constants';
import {
  getCookie, getDomainName,
  getUrlProtocol,
  getVisitorIdentifier,
  isMobileBrowser
} from '../commons/HelperFunctions';
import { getPreformanceMetrics } from '../commons/Performance';

/**
 * Create a base lander event with standard field names
 * This is mainly created for naming convention
 * @param eventType parking event type
 * @param landerConfig lander param API response
 * @returns object
 *
 * Note: Pleas use caution while accessing nested object for `landerConfig`.
 * If landerConfig is an empty object (no lander-param for the domain),
 * accessing nested object results into an error.
 */
export function createBaseLanderEvent(eventType, landerConfig) {
  const perf = getPreformanceMetrics();

  // empty obj {} are used to guard against nested access
  const domain = landerConfig.domain || {};
  const adSense = landerConfig.adSense || {};
  const lander = landerConfig.lander || {};
  const experiment = landerConfig.experiment || {};
  const banner = lander.banner || {};
  const bannerType = banner.show ? banner.type : null;

  const domLoadTime = perf && perf.domComplete ? perf.domComplete() : 0;
  const loadEventEnd = perf && perf.loadEventEnd ? perf.loadEventEnd() : 0;

  return {
    eventType: eventType,
    errorCode: null,
    landerParamApiStatus: null,
    xRequestId: null,
    createdAt: new Date().toISOString(),
    domain: domain.rootDomain,
    domainStatus: (domain.status || {}).internal,
    system: landerConfig.system,
    dataSource: landerConfig.dataSource,
    isTurnKeyReseller: typeof landerConfig.turnKeyReseller !== 'undefined',
    resellerPlid: (landerConfig.turnKeyReseller || {}).privateLabelId,
    isCname: landerConfig.dataSource === 'CNAME',
    rotatorId: landerConfig.rotatorId,
    account: landerConfig.account,
    drid: adSense.drid,
    pubId: adSense.pubId,
    channel: adSense.channel,
    feedProvider: landerConfig.adSense ? FeedProvider.GOOGLE_AD_SENSE : null,
    template: lander.template,
    sToken: null,
    keyword: null,
    keywordOrigin: null,
    feedStatusErrorCode: null,
    isAdult: null,
    bannerType: bannerType,
    experimentId: experiment.key,
    experimentBucket: experiment.bucket,
    ip: getCookie(LanderCookie.CLIENT_IP),
    country: getCookie(LanderCookie.COUNTRY),
    // remove quotes from city name ("San Francisco")
    city: getCookie(LanderCookie.CITY).replace(/["]+/g, ''),
    visitorId: getVisitorIdentifier(),
    protocol: getUrlProtocol(),
    userAgent: navigator.userAgent,
    httpReferer: document.referrer ? document.referrer : null,
    domLoadTime: domLoadTime,
    pageLoadTime: loadEventEnd,
    isMobile: isMobileBrowser()
  };
}

/**
 * Send FOS Redirect event
 * @param queryConfig
 */
export function createParkWebRedirectFosEvent(queryConfig) {
  return {
    eventType: EventType.REDIRECT_FOS,
    domain: getDomainName(queryConfig),
    createdAt: new Date().toISOString(),
    system: System.PARKWEB,
    ip: getCookie(LanderCookie.CLIENT_IP),
    country: getCookie(LanderCookie.COUNTRY),
    city: getCookie(LanderCookie.CITY),
    visitorId: getVisitorIdentifier(),
    protocol: getUrlProtocol(),
    userAgent: navigator.userAgent,
    httpReferer: document.referrer ? document.referrer : null,
    isMobile: isMobileBrowser()
  };
}

/**
 * Convert event object to JSON string
 * Ignores null, undefined or empty values in JSON
 * @param eventObj
 * @returns {*}
 */
export function eventToJsonString(eventObj) {
  let eventJson;
  try {
    // ignore null, undefined or empty values
    eventJson = JSON.stringify(eventObj, (key, value) => {
      if (value !== null && typeof value !== 'undefined' && value !== '')
        return value;
    });
  } catch (e) {
    Logger.error(e);
  }
  Logger.debug('lander event: ' + eventJson);
  return eventJson;
}

/**
 * Publish Park Page Event: CLICK, VISIT, etc.
 * Ignores `null` or `undefined` values.
 * @param apiEndpoint API endpoint for POST request
 * @param eventObj Lander event object
 */
export default function postEvent(apiEndpoint, eventObj) {
  const eventJson = eventToJsonString(eventObj);

  // Perform POST requst and send event as JSON
  fetch(apiEndpoint, {
    method: 'POST',
    credentials: 'omit', // Never send or receive cookies.
    body: eventJson,
    headers: {
      'Content-Type': 'application/json'
    }
  }).catch(function (res) {
    Logger.error('error publishing park page event - ' + res.toString());
  });
}
