import 'react-app-polyfill/ie9';
import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';
import ParkingLander from './ParkingLander';

ReactDOM.render(
  <ParkingLander/>,
  document.getElementById('contentMain')
);
