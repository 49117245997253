import React, { Component } from 'react';
import { fetchCached, LanderCache } from './commons/FetchCached';
import {
  DisplayType,
  DomainStatus,
  ErrorCode,
  EventType,
  FetchStatus,
  LanderCookie,
  QueryParam,
  Settings,
  System
} from './commons/Constants';
import ForSalePage from './forsale/ForSalePage';
import TargetedLander from './parkweb/TargetedLander';
import AdSensePage from './adsense/AdSensePage';
import {
  createLanderConfig,
  parseLanderQueryParam
} from './commons/LanderConfig';
import { ErrorPage } from './commons/LanderError';
import { Logger } from './commons/Logger';
import {
  generateUUID,
  getCookie,
  getDomainName
} from './commons/HelperFunctions';
import postEvent, { createBaseLanderEvent } from './commons/LanderEvent';
import { IntlProvider } from 'react-intl';
import { getLocaleFromBrowser, getLocalizedMessages } from './i18n/locale.js';

export default class ParkingLander extends Component {
  constructor(props) {
    super(props);

    this.state = {
      fetchStatus: FetchStatus.PENDING, // API request status, initially its pending
      notFound: false,
      queryConfig: parseLanderQueryParam(window.location.search), // URL query parameters object
      landerApiResult: {}, // lander (domain) configuration from API
      landerSystem: null, // for different parking systems
      landerApiStatus: null, // track lander param HTTP status in case of errors
      landerApiUrl: null, // track lander API url so that we know which one failed in case of failure
      landerApiError: null, // track error detail
      xRequestId: null // request id for lander-param API
    };

    // Set the Logger level to trace when debug mode is activated via Settings or query param
    if (Settings.DEBUG_MODE || this.state.queryConfig[QueryParam.DEBUG_MODE]) {
      Logger.level = Logger.Level.TRACE;
    }
    Logger.info('Log level = ' + Logger.level);
  }

  componentDidMount() {
    // Call parking lander API per system, uses cookie or LANDER_SYSTEM setting
    let landerSystem = getCookie(LanderCookie.SYSTEM);
    if (!landerSystem) {
      // if cookie can't be read, try LANDER_SYSTEM var defined in `index file`
      landerSystem = window.LANDER_SYSTEM;
    }

    const domainName = getDomainName(this.state.queryConfig);

    let landerApi;
    if (landerSystem === System.PARKWEB) {
      const trafficTarget = getCookie(LanderCookie.TRAFFIC_TARGET);
      landerApi = `${Settings.PARKWEB_LANDER_API}/v1/parking/landers/${domainName}?trafficTarget=${trafficTarget}`;
    } else {
      landerApi = `${Settings.DOMAIN_CONFIG_API}/v1/domains/${domainName}/landerParams`;
    }

    const xRequestId = generateUUID();
    const parent = this;

    // Fetch domain configuration to render the page
    // As this is method is called during did mount, you need to be aware that if gets unmounted again
    // before the fetch function completes you will generate errors as this.setState will become a NO-OP.
    fetchCached(landerApi, {
      ttl:
        landerSystem === System.PARKWEB
          ? LanderCache.DefaultParkwebTtl
          : LanderCache.DefaultTtl,
      method: 'GET',
      credentials: 'include',
      headers: {
        'X-Request-Id': xRequestId
      }
    })
      .then(function (response) {
        const notFound = response.status === 404;

        if (response.ok || notFound) {
          parent.setState({
            notFound: notFound
          });

          return response.json();
        }

        throw Error(response.status); // Error with HTTP Status code as a message
      })
      .then(function (json) {
        parent.setState({
          landerApiResult: json,
          landerSystem: landerSystem,
          fetchStatus: FetchStatus.SUCCESS
        });
      })
      .catch(function (error) {
        const httpCode = parseInt(error.message, 10); // message is HTTP Status Code

        parent.setState({
          fetchStatus: FetchStatus.FAILURE,
          landerApiStatus: httpCode ? httpCode : 0, // let's use 0 to indicate failure to parse status
          // from message to differentiate from actual 503 from server
          landerApiUrl: landerApi,
          landerApiError: error,
          xRequestId: xRequestId
        });
      });
  }

  // eslint-disable-next-line complexity
  render() {
    var locale = 'en-US';
    var useMessages = getLocalizedMessages(locale);
    // Wait for the domain config data to be available
    if (this.state.fetchStatus === FetchStatus.PENDING) {
      return null;
    }

    // Fetch data failed - error page
    if (this.state.fetchStatus === FetchStatus.FAILURE) {
      this.sendLanderParamApiErrorEvent(
        this.state.landerApiResult,
        this.state.queryConfig
      ); // custom error event
      return (
        <IntlProvider locale={ locale } messages={ useMessages }>
          <ErrorPage
            landerConfig={ this.state.landerApiResult }
            queryConfig={ this.state.queryConfig }
            errorCode={ ErrorCode.ERROR_FETCHING_DOMAIN_CONFIG }
            sendEvent={ false }
          />
        </IntlProvider>
      );
    }

    if (this.state.notFound) {
      return (
        <IntlProvider locale={ locale } messages={ useMessages }>
          <ErrorPage
            landerConfig={ this.state.landerApiResult }
            queryConfig={ this.state.queryConfig }
            errorCode={ ErrorCode.ERROR_FETCHING_DOMAIN_CONFIG }
            sendEvent={ false }
          />
        </IntlProvider>
      );
    }

    // For Parkweb Targeted Lander Page.
    if (
      this.state.landerSystem === System.PARKWEB &&
      this.state.landerApiResult.targeted
    ) {
      return (
        <IntlProvider locale={ locale } messages={ useMessages }>
          <TargetedLander
            targeted={ this.state.landerApiResult.targeted }
            queryConfig={ this.state.queryConfig }
          />
        </IntlProvider>
      );
    }

    // For Parking and Parkweb Untargeted lander
    let landerConfig;
    if (
      this.state.landerSystem === System.PARKWEB &&
      this.state.landerApiResult.untargeted
    ) {
      landerConfig = createLanderConfig(
        this.state.landerApiResult.untargeted,
        this.state.queryConfig
      );
    } else {
      landerConfig = createLanderConfig(
        this.state.landerApiResult,
        this.state.queryConfig
      );
    }
    // Use browser language if enable i18n
    if (landerConfig.lander.i18n === true) {
      locale = getLocaleFromBrowser();
      useMessages = getLocalizedMessages(locale);
    }
    // Verify the domain status (trademark, blacklisted, etc.)
    if (landerConfig.domain.status.internal !== DomainStatus.ACTIVE) {
      return (
        <IntlProvider locale={ locale } messages={ useMessages }>
          <ErrorPage
            landerConfig={ landerConfig }
            errorCode={ ErrorCode.ERROR_DOMAIN_STATUS_INVALID }
          />
        </IntlProvider>
      );
    }

    // Check for Turnkey Reseller's redirectURL
    const turnKeyReseller = landerConfig.turnKeyReseller || {};
    if (
      turnKeyReseller.redirectUrl &&
      turnKeyReseller.redirectUrl.trim().length
    ) {
      Logger.debug(
        'Redirecting to reseller page: ' +
          landerConfig.turnKeyReseller.redirectUrl
      );
      window.location.replace(landerConfig.turnKeyReseller.redirectUrl);
    }

    // Render appropriate page (AdSense, ForSale, etc.)
    switch (landerConfig.displayType) {
      case DisplayType.ADS:
        return (
          <IntlProvider locale={ locale } messages={ useMessages }>
            <AdSensePage
              landerConfig={ landerConfig }
              queryConfig={ this.state.queryConfig }
            />
          </IntlProvider>
        );
      case DisplayType.FOR_SALE:
        return (
          <IntlProvider locale={ locale } messages={ useMessages }>
            <ForSalePage
              landerConfig={ landerConfig }
              queryConfig={ this.state.queryConfig }
            />
          </IntlProvider>
        );
      default:
        return (
          <IntlProvider locale={ locale } messages={ useMessages }>
            <ErrorPage
              landerConfig={ landerConfig }
              errorCode={ ErrorCode.ERROR_UNKNOWN_DOMAIN_TYPE }
            />
          </IntlProvider>
        );
    }
  }

  // Send ERROR event if fails to fetch lander-param via API
  // TODO: consider extracting out logic to send error events
  // Currently, both LanderError.js and ParkingLander.js have
  // their own logic to send error events, which are mostly duplicate
  // Should have a common function
  sendLanderParamApiErrorEvent(landerConfig, queryConfig) {
    const event = createBaseLanderEvent(EventType.ERROR, landerConfig);
    event.errorCode = ErrorCode.ERROR_FETCHING_DOMAIN_CONFIG;
    event.landerParamApiStatus = this.state.landerApiStatus;
    event.landerApiUrl = this.state.landerApiUrl;
    event.landerApiError = this.state.landerApiError;
    event.xRequestId = this.state.xRequestId;
    event.sToken = queryConfig[QueryParam.S_TOKEN];

    // fill in domain name if lander-param API fails
    if (typeof event.domain === 'undefined') {
      event.domain = queryConfig
        ? getDomainName(queryConfig)
        : window.location.hostname;
    }
    postEvent(Settings.EVENT_PUBLISH_API, event);
  }
}
