import React, { Component } from 'react';
import LayoutStyle, {
  getAdsConfig,
  getRelatedLinksConfig,
  getSearchboxConfig,
  getPageLevelParams,
  SimpleLayoutBackground
} from './LayoutStyle';
import PropTypes from 'prop-types';
import RectHtmlParser from 'html-react-parser';
import {
  AdSense,
  getDefaultAdsOptions,
  getDefaultPageOptions,
  getDefaultRelatedLinkOptions,
  getDefaultSearchboxOptions
} from '../AdSense';
import { setupCallbackTracker } from '../AdSenseCallback';
import { QueryParam, System, SystemDefault } from '../../commons/Constants';
import { Logger } from '../../commons/Logger';
import { Banner } from '../../banner/Banner';
import { FormattedMessage } from 'react-intl';

export default class SimpleLayout extends Component {

  componentDidMount() {

    const lc = this.props.landerConfig;
    const qc = this.props.queryConfig;
    const template = lc.lander.template;

    // Setup callback and tracking
    setupCallbackTracker(lc, qc);

    // Create the caf options:
    // Merge the template-specific options with the default settings.
    // Template-specific settings (right hand side of the spread operator) will override default options.

    const pageOptions = { ...getDefaultPageOptions(lc), ...getPageLevelParams(lc) };
    let pageContentOptions;

    // Page content - Related link or Sponsored listings
    if (this.props.page === AdSense.page.RELATED_LINKS) {
      pageContentOptions = { ...getDefaultRelatedLinkOptions(lc, qc), ...getRelatedLinksConfig(template) };
    } else {
      pageContentOptions = { ...getDefaultAdsOptions(lc, qc), ...getAdsConfig(template) };
    }

    // Call Google caf library
    // Searchbox options must be passed as the last argument. 2 separate calls also works.
    if (typeof window.google !== 'undefined') {
      if (qc[QueryParam.SEARCH_BOX]) {
        Logger.debug('Google CAF request with searchbox on');
        const searchboxOptions = { ...getDefaultSearchboxOptions(lc, qc), ...getSearchboxConfig(template) };
        window.google.ads.domains.Caf(pageOptions, pageContentOptions, searchboxOptions);
      } else {
        Logger.debug('Google CAF request with searchbox off');
        window.google.ads.domains.Caf(pageOptions, pageContentOptions);
      }
    }

  }

  render() {

    const landerConfig = this.props.landerConfig;
    const queryConfig = this.props.queryConfig;
    const page = this.props.page;
    const content = this.props.content;

    // banner
    const bannerContent = <Banner landerConfig={landerConfig} queryConfig={queryConfig} content={content} />;


    let domain;
    if (typeof queryConfig[QueryParam.SHOW_DOMAIN] === 'boolean'?
    queryConfig[QueryParam.SHOW_DOMAIN]:landerConfig.lander.showDomain) {     // default is false for this template
      const domainName = landerConfig.lander.domainDisplayName || landerConfig.domain.rootDomain;
      domain = <Domain domain={domainName} />;
    }

    let search;
    if (queryConfig[QueryParam.SEARCH_BOX]) {      // default is false for this template
      search = <SearchBox />;
    }

    let adsenseContent;
    if (page === AdSense.page.RELATED_LINKS) {
      adsenseContent = <RelatedLinks />;
    } else {
      adsenseContent = <SponsoredListings />;
    }

    let headerElement;
    if (landerConfig.lander.headerHtml) {
      let decodedHtml = "";
      try {
        decodedHtml = atob(landerConfig.lander.headerHtml)
      }catch (e) {
        Logger.error('headerHtml value is not properly encoded' + toString(e));
      }
      headerElement = <HeaderHtmlElement headerHtml={decodedHtml} />;
    } else if (landerConfig.lander.headerText) {
      headerElement = <HeaderTextElement headerText={landerConfig.lander.headerText} />;
    }

    return (
      <div>
        <SimpleLayoutBackground template={landerConfig.lander.template} />
        {bannerContent}
        <div id='contentLayout'>
          <LayoutStyle template={landerConfig.lander.template} />
          {headerElement}
          {domain}
          {adsenseContent}
          {search}
          <Footer landerConfig={landerConfig} queryConfig={queryConfig} />
        </div>
      </div>
    );
  }

}

SimpleLayout.propTypes = {
  page: PropTypes.string.isRequired,
  landerConfig: PropTypes.object.isRequired,
  queryConfig: PropTypes.object.isRequired,
  content: PropTypes.object.isRequired,
};


export function HeaderHtmlElement({ headerHtml }) {
  return <div id='headerElement'>{RectHtmlParser(headerHtml)}</div>;
}

export function HeaderTextElement({ headerText }) {
  return <div id='headerElement'>{headerText}</div>;
}

export function Domain({ domain }) {
  return (
      <div id='domain'>{domain}</div>
  );
}

export function SearchBox() {
  return <div id='searchbox'>Ads are blocked</div>;
}
export function RelatedLinks() {
  return (
    <div id='related_links'>
      <div id='relatedLinks'>Ads are blocked</div>
    </div>
  );
}
export function SponsoredListings() {
  return (
    <div id='sponsored_listings'>
      <div id='ads'>Ads are blocked</div>
    </div>
  );
}

export function Footer({ landerConfig, queryConfig }) {

  let disclaimer;
  const isParkWeb = landerConfig.system === System.PARKWEB && !landerConfig.turnKeyReseller;
  if (isParkWeb) {
    disclaimer = <div className='footerLine footerColor'><Disclaimer /></div>
  }

  let footerElement;
  if (landerConfig.lander.footerHtml) {
      let decodedHtml = "";
      try {
        decodedHtml = atob(landerConfig.lander.footerHtml);
      } catch (e) {
        Logger.error('footerHtml value is not properly encoded' + toString(e));
      }
    footerElement = <div className='footerLine footerColor'><FooterHtmlElement footerHtml={decodedHtml} /></div>;
  } else if (landerConfig.lander.footerText) {
    footerElement = <div className='footerLine footerColor'><FooterTextElement footerText={(landerConfig.lander.footerText)} /></div>;
  }

  return (
    <div id='footer'>
      {footerElement}
      <div className='footerLine footerColor'><Copyright landerConfig={landerConfig} queryConfig={queryConfig} /> &nbsp; | &nbsp; <Privacy
        landerConfig={landerConfig} /></div>
      {disclaimer}
    </div>
  );
}

export function FooterHtmlElement({ footerHtml }) {
  return <div id='footerElement'>{RectHtmlParser(footerHtml)}</div>;
}

export function FooterTextElement({ footerText }) {
  return <div id='footerElement'>{footerText}</div>;
}

export function Copyright({ landerConfig, queryConfig }) {
  if (queryConfig[QueryParam.SHOW_DOMAIN]) { // default is false for this template
    const domainName = landerConfig.lander.domainDisplayName || landerConfig.domain.rootDomain;
    return <span id='copyright'>Copyright &copy; {domainName}. &nbsp;All rights reserved.</span>;
  }
  return <span id='copyright'>Copyright &copy; &nbsp;All rights reserved.</span>;
}

export function Privacy({ landerConfig }) {
  const privacyUrl = (landerConfig.turnKeyReseller || {}).privacyUrl || SystemDefault.PRIVACY_POLICY_URL;

  function getModalPrivacy() {
    window.open(privacyUrl, 'privacy',
      'width=520,height=400,left=250,top=250,menubar=no,status=yes,toolbar=no,scrollbars=yes,resizable=yes');
  }
  if(landerConfig.system === 'PW')
    return <span id='privacy'><a href='#!' onClick={getModalPrivacy}>Privacy Policy</a></span>;
  return <span id='privacy'><a href='#!' onClick={getModalPrivacy}><FormattedMessage id='privacyPolicyText'/></a></span>;
}

export function Disclaimer() {
  return <span id='disclaimer'>This Web page is parked <span className='highlight'>FREE</span>, courtesy of GoDaddy</span>;
}

Domain.propTypes = {
  domain: PropTypes.string.isRequired
};

Copyright.propTypes = {
  landerConfig: PropTypes.object.isRequired,
  queryConfig: PropTypes.object.isRequired
};

Privacy.propTypes = {
  landerConfig: PropTypes.object.isRequired
};

HeaderHtmlElement.propTypes = {
  headerHtml: PropTypes.string.isRequired
};

HeaderTextElement.propTypes = {
  headerText: PropTypes.string.isRequired
};

FooterHtmlElement.propTypes = {
  footerHtml: PropTypes.string.isRequired
};

FooterTextElement.propTypes = {
  footerText: PropTypes.string.isRequired,
};

Footer.propTypes = {
  landerConfig: PropTypes.object.isRequired,
  queryConfig: PropTypes.object.isRequired
};
