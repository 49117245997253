import React from 'react';
import CssRule, { cssRule, cssRules } from '../../commons/CssRule';
import { GoogleCaf } from '../AdSense';

export function getSimple2CLightPageLevel() {
  return {
    uiOptimize: true,
    styleId: GoogleCaf.styleId.SIMPLE_2C_LIGHT
  };
}

export function getSimple2CSearchbox() {
  return {
    radiusSearchInputBorder: '5px', // Use this parameter to add rounded borders to the search input field AND the search button.
    heightSearchInput: '22px',
    heightSearchButton: '22px',
    fontSizeSearchInput: '12px',
    fontSizeSearchButton: '12px'
  };
}

export function getSimple2CRelatedLinks() {
  return {
    rolloverLinkColor: '#5595E9',
    colorAttribution: '#b4b4b4',
    colorBackground: 'transparent',
    attributionSpacingBelow: '2',
    attributionUppercase: false,
    attributionBold: false,
    fontSizeAttribution: '11px',
    colorTitleLink: '#e08d20',
    titleBold: false,
    fontSizeTitle: '24',
    lineHeightTitle: '40',
    noTitleUnderline: true,
    adIconUrl: 'https://afs.googleusercontent.com/dp-namemedia/bullet-arrows-orange.png',
    adIconHeight: '18', // Required (if adIconUrl is set)
    adIconWidth: '18', // Required (if adIconUrl is set)
    adIconSpacingAbove: '10',
    adIconSpacingAfter: '10',
    verticalSpacing: '2',
    adBorderSelections: 'bottom',
    colorAdBorder: '#525252'
  };
}

export function getSimple2CLightRelatedLinks() {
  return {
    ...getSimple2CRelatedLinks(),
    rolloverLinkColor: '#5595E9',
    colorAttribution: '#2b2b2b',
    colorTitleLink: '#e08d20',
    colorAdBorder: '#525252',
    colorText: '#666666'
  };
}

export function getSimple2CAds() {
  return {
    colorBackground: 'transparent',
    colorText: '#f8f8f8',
    rolloverLinkColor: '#5595E9',
    rolloverLinkUnderline: false,
    adIconUrl: 'https://afs.googleusercontent.com/dp-namemedia/bullet-arrows-orange.png',
    adIconHeight: '22',
    adIconWidth: '22',
    adIconSpacingAbove: '10',
    adIconSpacingAfter: '10',
    verticalSpacing: '24',
    colorAdSeparator: '#525252',
    linkTarget: '_blank',
    attributionSpacingBelow: '10',
    attributionUppercase: false,
    colorAttribution: '#b4b4b4',
    fontSizeAttribution: '10',
    attributionBold: false,
    colorTitleLink: '#e08d20',
    fontSizeTitle: '20',
    noTitleUnderline: true,
    lineHeightTitle: '35',
    titleBold: false,
    fontSizeDescription: '16',
    lineHeightDescription: '28',
    colorDomainLink: '#5595E9',
    fontSizeDomainLink: '16',
    lineHeightDomainLink: '28'
  };
}

export function getSimple2CLightAds() {
  return {
    ...getSimple2CAds(),
    colorText: '#f8f8f8',
    rolloverLinkColor: '#5595E9',
    colorAdSeparator: '#525252',
    colorAttribution: '#b4b4b4',
    colorTitleLink: '#e08d20',
    colorDomainLink: '#5595E9'
  };
}

function commonRules() {
  return cssRules([new CssRule(
    'body', [
      'margin: 0;',
      'padding: 0;',
      'background-color: #333;',
      'font-family: \'Arial\', serif;'
    ]), new CssRule(
    '#tdfsBanner', [
      'background:#00ff00;',
      'font-size: 15px;',
      'padding: .5em;',
      '-webkit-transition: padding 400ms;',
      'transition: padding 400ms;'
    ]), new CssRule(
    '#tdfsBanner:hover', [
      'padding: .7em .5em;']), new CssRule(
    '#tdfsLink, #tdfsAuctionsLink, #tdfsContactLink, #tdfsCustomForSaleLink', [
      'margin:0 auto;',
      'max-width: 960px;',
      'width: 95%;',
      'text-align: center;']), new CssRule(
    '#tdfsLink a, #tdfsAuctionsLink a, #tdfsContactLink a, #tdfsCustomForSaleLink a', [
      'color: #000;',
      'display: block;',
      'font-size: 1em;',
      'font-weight: 300;',
      'text-decoration: none;',
      'text-shadow:0 1px rgba(0,0,0,.5);']), new CssRule(
    'a', [
      'text-decoration: none;']), new CssRule(
    '#domain', [
      'margin-left:0px;',
      'margin-bottom:10px;',
      'font-size:26px;',
      'text-align: center;',
      'color:#ccc;']), new CssRule(
    '#searchbox', [
      'margin:0 auto;',
      'margin-top: 10px;',
      'margin-bottom: 10px;',
      'width: 50%;',
      'font-weight: normal;',
      'text-align: center;',
      'height: 24px;']), new CssRule(
    '#headerElement', [
      'padding: 10px;',
      'color: #b4b4b4;',
      'font-size: 11px;',
      'text-align: center;']), new CssRule(
    '#footer', [
      'width: auto;',
      'padding-top: 70px;']), new CssRule(
    '#privacy a', [
      'text-decoration: none;',
      'color:#b4b4b4;']), new CssRule(
    '.footerLine', [
      'line-height: 25px;',
      'font-size: 11px;',
      'text-align: center;']), new CssRule(
    '.footerColor', [
      'color:#b4b4b4;']), new CssRule(
    '#loading', [
      'color: white;']), new CssRule(
    '#contentLayout', [
      'margin: auto;']), new CssRule(
    '#sponsored_listings, #related_links', [
      'border-radius: 5px;',
      'background-color: #484848;',
      'padding-top: 10px;']), new CssRule(
    '.highlight', [
      'color: #FD7400;'])
  ]);
}


export function Simple2C() {
  return <style>
    {commonRules()}
    {cssRule('#contentLayout', [
      'width: 75%;',
      'min-width: 500px;',
      'max-width: 800px;',
      'padding: 10px;'
    ])}
    {cssRule('#sponsored_listings, #related_links', [
      'padding: 30px;'
    ])}
  </style>;
}

export function Simple2CMobile() {
  return <style>
    {commonRules()}
    {cssRule('#sponsored_listings, #related_links', [
      'border-radius: 0px;',
      'padding-top: 10px;'
    ])}
    {cssRule('#footer', [
      'width: auto;',
      'padding-top: 20px;'
    ])}
    {cssRule('#copyright', [
      'margin: 0px;'
    ])}
  </style>;
}

export function Simple2CLight() {
  return <style>
    {commonRules()}
    {cssRule('body', [
      'background-color: #EEF6FF;'
    ])}
    {cssRule('#contentLayout', [
      'width: 75%;',
      'min-width: 500px;',
      'max-width: 800px;',
      'padding: 10px;'
    ])}
    {cssRule('#domain', [
      'color:#999;'
    ])}
    {cssRule('#sponsored_listings, #related_links', [
      'background-color: #EEF6FF;',
      'padding-top: 30px;'
    ])}
    {cssRule('#copyright, #privacy a', [
      'color: #2b2b2b;'
    ])}
    {cssRule(
      '.footerColor', [
        'color:#2b2b2b;'
    ])}
  </style>;
}

export function Simple2CLightMobile() {
  return <style>
    {commonRules()}
    {cssRule('body', [
      'background-color: #EEF6FF;'
    ])}
    {cssRule('#sponsored_listings, #related_links', [
      'background-color: #EEF6FF;',
      'border-radius: 0px;',
      'padding-top: 10px;'
    ])}
    {cssRule('#footer', [
      'width: auto;',
      'padding-top: 20px;'
    ])}
    {cssRule('#copyright', [
      'margin: 0px;',
      'color: #2b2b2b;'
    ])}
    {cssRule('#privacy a', [
      'color: #2b2b2b;'
    ])}
    {cssRule(
      '.footerColor', [
        'color:#2b2b2b;'
    ])}
  </style>;
}
